import React, { Fragment } from 'react'
import { navigate } from 'gatsby'
import Skeleton from 'react-loading-skeleton'
import { useApi } from '../../utils/api'
import { categorizeList } from '../../utils'
import { Card, User, Project, Total } from '../../components'
import BillingPeriodDropdown, {
  useBillingPeriodDropdown
} from '../../components/BillingPeriodDropdown'
import styles from './ProjectReports.module.scss'

export default ({ billingKey }) => {
  const { defaultValue, byKey } = useBillingPeriodDropdown()

  const selected = billingKey || defaultValue

  const onClickProject = id => {
    navigate(`/projects/${id}/report/${selected}`)
  }

  const selectedMonth = byKey ? byKey[selected] : {}

  return (
    <section>
      <div className='container'>
        <h4>Tímaskýrslur</h4>

        <div className='row mb-5'>
          <div className='col-sm-4'>
            <BillingPeriodDropdown
              value={selected}
              onChange={({ value }) => {
                navigate(`/projects/reports/${value}`)
              }}
            />
          </div>
        </div>

        <h3>{selectedMonth.label}</h3>

        <div className='mb-5'>
          <ProjectsForBillingPeriod
            onClickProject={onClickProject}
            billingPeriodKey={selectedMonth.key}
          />
        </div>
      </div>
    </section>
  )
}

const ProjectsForBillingPeriod = ({ billingPeriodKey, onClickProject }) => {
  if (!billingPeriodKey) {
    return null
  }

  const url = `/projects/by-billing-period/${billingPeriodKey}`

  const { loading, data, error } = useApi(url)

  if (error) {
    console.error(error)
  }

  return (
    <Fragment>
      {loading && <Skel />}

      {error && <h1 style={{ color: 'red' }}>Error!</h1>}

      {data &&
        data.projects.map(project => {
          const projectId = project.projectId

          return (
            <ConnectedProject
              key={projectId}
              onClick={() => {
                onClickProject(projectId)
              }}
              project={project}
            />
          )
        })}
    </Fragment>
  )
}

const ConnectedProject = ({ project, onClick }) => {
  const { name, clientName, userAssignments, totalHours } = project

  const { projectManagers } = categorizeList(userAssignments, {
    projectManagers: u => u.is_project_manager
  })

  return (
    <Card withPadding reducedMargin onClick={onClick}>
      <Project className='row'>
        <div className='col-sm-5'>
          <h6 className={styles.client}>{clientName}</h6>
          <h4 className={styles.projectName}>{name}</h4>
        </div>

        <div className='col-sm-2'>
          {projectManagers.map(({ user: { id } }) => (
            <User userId={id} key={id} />
          ))}
        </div>

        <div className='col-sm-1'>
          <Total total={totalHours} />
        </div>
      </Project>
    </Card>
  )
}

const Skel = () =>
  new Array(6).fill(null).map((x, i) => (
    <Card withPadding reducedMargin key={i}>
      <Project className='row'>
        <div className='col-sm-5'>
          <h6 className={styles.client}>
            <Skeleton width='20%' />
          </h6>
          <h4 className={styles.projectName}>
            <Skeleton width='50%' />
          </h4>
        </div>

        <div className='col-sm-2'>
          <div className={styles.manager}>
            <Skeleton width={110} />
          </div>
        </div>

        <div className='col-sm-1'>
          <Skeleton width='40' />
        </div>

        <div className='col-sm-2'>
          <Skeleton />
        </div>
      </Project>
    </Card>
  ))
