import React, { Fragment, useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useInView } from 'react-intersection-observer'
import { useApi } from '../../utils/api'
import { categorizeList } from '../../utils'
import {
  Card,
  User,
  ProjectInSidePanel,
  ProgressBar,
  Project,
  Total
} from '../../components'
import SidePanel from '../../components/SidePanel'
import styles from './ProjectList.module.scss'
import {
  addWeeks,
  formatISO,
  startOfWeek,
  differenceInCalendarDays
} from 'date-fns'

export default () => {
  const [sidePanelProjectId, setSidePanelProjectId] = useState(null)

  const onClickProject = id => setSidePanelProjectId(id)

  const { title, date } = getTargetWeek()

  return (
    <section>
      <div className='container'>
        <h4>{title}</h4>

        <div className='mb-5'>
          <ProjectsRecent date={date} onClickProject={onClickProject} />
        </div>

        <h4>Öll ongoing verkefni</h4>

        <div className='mb-5'>
          <ProjectsAll onClickProject={onClickProject} />
        </div>

        <SidePanel
          shown={!!sidePanelProjectId}
          options={{
            onRequestClose: () => {
              setSidePanelProjectId(null)
            }
          }}
        >
          {sidePanelProjectId && (
            <ProjectInSidePanel projectId={sidePanelProjectId} />
          )}
        </SidePanel>
      </div>
    </section>
  )
}

const getTargetWeek = () => {
  const dateCurrent = Date.now()
  const daysSinceStartOfWeek = differenceInCalendarDays(
    dateCurrent,
    startOfWeek(dateCurrent, { weekStartsOn: 1 })
  )

  const showLastWeek = daysSinceStartOfWeek < 2

  return {
    date: showLastWeek ? addWeeks(dateCurrent, -1) : dateCurrent,
    title: showLastWeek ? 'Síðasta vika' : 'Í vikunni'
  }
}

const ProjectsRecent = ({ date, ...rest }) => {
  const [dateStr] = formatISO(date).split('T')

  return <Page url={`/projects/with-activity/${dateStr}`} {...rest} />
}

const ProjectsAll = props => {
  const [pages, setPages] = useState([1])

  const onRequestPage = nextPage => {
    if (nextPage && !pages.includes(nextPage)) {
      setPages([...pages, nextPage])
    }
  }

  return pages.map(page => (
    <Page
      key={page}
      url={`/projects/${page}`}
      onRequestPage={onRequestPage}
      {...props}
    />
  ))
}

const Page = ({ url, onRequestPage: onRequestNextPage, onClickProject }) => {
  const { loading, data, error } = useApi(url)

  const goToNextPage = () => {
    onRequestNextPage(data.next_page)
  }

  if (error) {
    console.error(error)
  }

  const projectList = (data ? data.projects : []).filter(p => p.project)

  return (
    <Fragment>
      {loading && <Skel />}

      {error && <h1 style={{ color: 'red' }}>Error!</h1>}

      {projectList.map((projectInfo, i, arr) => {
        const isLast = i === arr.length - 1
        const projectId = projectInfo.project.projectId

        return (
          <ConnectedProject
            key={projectId}
            onClick={() => {
              onClickProject(projectId)
            }}
            projectInfo={projectInfo}
            onReportInView={onRequestNextPage && isLast ? goToNextPage : null}
          />
        )
      })}
    </Fragment>
  )
}

const ConnectedProject = ({ projectInfo, onReportInView, onClick }) => {
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView && onReportInView) {
      onReportInView()
    }
  }, [inView])

  const {
    project: { name, clientName, budget, userAssignments },
    totalHours,
    hours
  } = projectInfo

  const { projectManagers } = categorizeList(userAssignments, {
    projectManagers: u => u.is_project_manager
  })

  return (
    <Card withPadding reducedMargin onClick={onClick}>
      <Project ref={ref} className='row'>
        <div className='col-sm-5'>
          <h6 className={styles.client}>{clientName}</h6>
          <h4 className={styles.projectName}>{name}</h4>
        </div>

        <div className='col-sm-2'>
          {projectManagers.map(({ user: { id } }) => (
            <User userId={id} key={id} />
          ))}
        </div>

        <div className='col-sm-1'>
          <Total total={hours || totalHours} />
        </div>

        <div className='col-sm-2'>
          <ProgressBar budget={budget || 0} total={totalHours} />
        </div>
      </Project>
    </Card>
  )
}

const Skel = () =>
  new Array(6).fill(null).map((x, i) => (
    <Card withPadding reducedMargin key={i}>
      <Project className='row'>
        <div className='col-sm-5'>
          <h6 className={styles.client}>
            <Skeleton width='20%' />
          </h6>
          <h4 className={styles.projectName}>
            <Skeleton width='50%' />
          </h4>
        </div>

        <div className='col-sm-2'>
          <div className={styles.manager}>
            <Skeleton width={110} />
          </div>
        </div>

        <div className='col-sm-1'>
          <Skeleton width='40' />
        </div>

        <div className='col-sm-2'>
          <Skeleton />
        </div>
      </Project>
    </Card>
  ))
