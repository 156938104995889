import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useApi } from '../../utils/api'
import { indexedArray, categorizeListByFn } from '../../utils'
import { ClientProject, User, Link, Total, Card } from '../../components'
import styles from './ProjectReportDetail.module.scss'
import { format } from 'date-fns'
import { is } from 'date-fns/locale'
import cn from 'classnames'

export default ({ billingKey, projectId }) => {
  const url = `/project/${projectId}/by-billing-period/${billingKey}`

  const { loading, data, error } = useApi(url)

  if (error) {
    console.error(error)
    return null
  }

  const { project = {}, timesheet = [] } = data || {}

  const crums = [
    ['/projects', 'Verkefni'],
    ['/projects/reports', 'Skýrslur'],
    [`/projects/reports/${billingKey}`, billingKey],
    [
      `/projects/${projectId}/reports/${billingKey}`,
      `${project.clientName}/${project.name}`
    ]
  ]

  const date = getDateFromBillingKey(billingKey)

  const totalHours = timesheet.reduce(sumHours, 0)

  const timesheetPerTask = categorizeListByFn(
    timesheet,
    ({ taskName }) => taskName
  )

  const tasksheet = Object.entries(timesheetPerTask).map(
    ([taskName, entries]) => {
      return {
        taskName,
        hours: entries.reduce(sumHours, 0),
        count: entries.length,
        users: entries.map(({ userId }) => userId).filter(unique)
      }
    }
  )

  return (
    <section>
      <div className='container'>
        <ul className={styles.crums}>
          {crums.map(([url, label]) => (
            <li key={url}>
              <Link to={url}>{label}</Link>
            </li>
          ))}
        </ul>

        <div className={`row ${styles.header}`}>
          <div className='col-7'>
            <ClientProject
              loading={loading}
              client={{
                id: project.clientId,
                name: project.clientName
              }}
              project={{
                id: project.projectId,
                name: project.name
              }}
            />
          </div>

          <div className='col-5 text-right'>
            <div>{format(date, 'MMMM, yyyy', { locale: is })}</div>
            <Total total={totalHours} /> tímar
          </div>
        </div>

        {error && <h1 style={{ color: 'red' }}>Error!</h1>}
        {loading && <Skel />}

        <Row header by='Unnið af' desc='Tegund' hours='Tímar' />

        {tasksheet.map(({ users, taskName, hours, count }) => (
          <Card key={taskName} reducedMargin>
            <Row
              kye={taskName}
              by={users.map(u => (
                <User key={u} userId={u} />
              ))}
              desc={
                <React.Fragment>
                  <h6>{taskName}</h6>
                  {count} tímaskráningar
                </React.Fragment>
              }
              hours={hours}
            />
          </Card>
        ))}

        <Row footer date='Samtals' hours={totalHours} />

        <h4 className='mt-3'>Allar færslur</h4>

        <Row header by='Unnið af' desc='Lýsing' date='Dags' hours='Tímar' />

        {timesheet.map(sheet => (
          <Card key={sheet.timesheetId} reducedMargin>
            <TimesheetRow sheet={sheet} />
          </Card>
        ))}

        <Row footer date='Samtals' hours={totalHours} />
      </div>
    </section>
  )
}

const sumHours = (acc, { hoursRounded }) => acc + hoursRounded

const unique = (val, i, arr) => arr.indexOf(val) === i

const Row = ({ by, desc, date, hours, header = false, footer = false }) => (
  <div
    className={cn('row', styles.row, {
      'pb-1': header,
      [styles.rowBold]: header || footer
    })}
  >
    <div className='pt-3 pb-3 pl-4 col-sm-2'>{by}</div>
    <div className='pt-3 pb-3 col-sm-6'>{desc}</div>
    <div className='pt-3 pb-3 col-sm-2'>{date}</div>
    <div className='pt-3 pb-3 col-sm-2'>{hours}</div>
  </div>
)

const getDateFromBillingKey = billingKey => {
  const [y, m] = billingKey.split('-')

  return new Date(parseInt(y, 10), parseInt(m, 10) - 1, 1)
}

const TimesheetRow = ({ sheet }) => {
  const spentDate = format(new Date(sheet.spentDate), 'd. MMMM', {
    locale: is
  })

  return (
    <Row
      by={<User userId={sheet.userId} />}
      desc={
        <React.Fragment>
          <p>{sheet.notes}</p>
          <p className={styles.taskName}>{sheet.taskName}</p>
        </React.Fragment>
      }
      date={spentDate}
      hours={sheet.hoursRounded}
    />
  )
}

const Skel = () =>
  indexedArray(3).map(i => (
    <Row
      key={i}
      by={
        <React.Fragment>
          <Skeleton circle />
          <Skeleton width={80} />
        </React.Fragment>
      }
      desc={
        <React.Fragment>
          <p>
            <Skeleton width={250} />
          </p>
          <p className={styles.taskName}>
            <Skeleton width={120} />
          </p>
        </React.Fragment>
      }
      date={<Skeleton width={120} />}
      hours={<Skeleton width={60} />}
    />
  ))
