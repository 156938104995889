import React, { useMemo } from 'react'
import { getAllBillingPeriods } from '../../utils'
import Dropdown from 'react-dropdown'
import './BillingPeriodDropdown.module.scss'

export default ({ value, onChange }) => {
  const { options } = useBillingPeriodDropdown()

  return <Dropdown value={value} onChange={onChange} options={options} />
}

export const useBillingPeriodDropdown = () => {
  return useMemo(() => {
    const { byYear, byKey } = getAllBillingPeriods(new Date())

    const options = byYear
      .map(({ year, months }) => ({
        type: 'group',
        name: year,
        items: months
          .map(({ label, key: value }) => ({ label, value }))
          .reverse()
      }))
      .reverse()

    const [
      {
        items: [{ value: defaultValue }]
      }
    ] = options

    return {
      options,
      defaultValue,
      byKey
    }
  }, [])
}
