import React from 'react'
import { Router } from '@reach/router'
import Main from '../../layouts/Main'
import { Transition } from '../../components/'
import ProjectList from '../../views/ProjectList'
import ProjectReports from '../../views/ProjectReports'
import ProjectReportDetail from '../../views/ProjectReportDetail'
import { Private } from '../../utils/authentication'
import { SharedHarvestDataProvider } from '../../utils/shared-data'
import { SidePanelProvider } from '../../components/SidePanel'

export default () => {
  return (
    <Private>
      <SharedHarvestDataProvider>
        <SidePanelProvider>
          <Main>
            <Transition>
              <Router>
                <ProjectList path='/projects' />
                <ProjectReports path='/projects/reports' />
                <ProjectReports path='/projects/reports/:billingKey' />
                <ProjectReportDetail path='/projects/:projectId/report/:billingKey' />
              </Router>
            </Transition>
          </Main>
        </SidePanelProvider>
      </SharedHarvestDataProvider>
    </Private>
  )
}
